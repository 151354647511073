export const VERSION_0 = 0
export const VERSION_1 = 1

export const isExactVersion = (licenseVersion, version) => {
  return version === licenseVersion
}

export const isExactOrMoreVersion = (licenseVersion, version) => {
  return version >= licenseVersion
}
