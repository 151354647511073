import { ref } from 'vue'
import { getPaddleLink, getPackages } from '@/application/services/message-packages.js'
import { useErrorHandler } from '@/application/composables/responseErrorHandler.js'
import { getLookupPaddleLink } from '@/application/services/lookup-api.js'
import { sendAuthRequestPaddleLink } from '@/application/services/auth-request-packages.js'

export function useMessagePackages(payType) {
  const payHandler = {
    lookup: getLookupPaddleLink,
    authRequest: sendAuthRequestPaddleLink,
  }[payType] || getPaddleLink

  const isDisabled = ref(false)
  const errorMessage = ref('')
  const { responseErrorHandler } = useErrorHandler()
  const submit = (value) => {
    isDisabled.value = true
    return payHandler(value.package)
      .then(({ data }) => {
        isDisabled.value = false
        return data ? data.url : null
      })
      .catch(err => {
        const status = err.response.status
        errorMessage.value = responseErrorHandler(status)
        isDisabled.value = false
        return false
      })
  }
  return {
    submit,
    isDisabled,
    errorMessage,
    getPackages,
  }
}
